import React from "react";
import styles from "./Menu.module.scss";
import Button from "../Buttons";
import buttonStyles from "../Buttons/Button.module.scss";
import { useBasePath } from "../../Context/BasePathContext"; // import the context hook

const Menu = ({
  name,
  items = [],
  type = "horizontal",
  className,
  listClassName,
  itemClassName,
  onCloseMenu,
}) => {
  const basePath = useBasePath(); // get the base path

  const constructPath = (path) => {
    if (path.startsWith("http://") || path.startsWith("https://")) {
      return path; // Return external links as is
    }
    if (path.startsWith("#")) {
      return path; // Return anchor links as is
    }
    if (basePath === "/") {
      return path.startsWith("/") ? path : `/${path}`; // Ensure leading slash for root paths
    }

    return path.startsWith("/") ? `${basePath}${path}` : `${basePath}/${path}`; // Handle basePath for non-root paths
  };

  return (
    <nav
      className={`${styles.nav} ${styles[type]} ${
        styles[className] ? styles[className] : ""
      }`}
      aria-label={name ? name : ""}>
      <ul className={`${styles.list} ${listClassName ? listClassName : ""}`}>
        {Array.isArray(items) &&
          items.map((item, index) => (
            <li className={styles.item} key={`menu__item_${index}`}>
              <Button
                className={
                  itemClassName ? itemClassName : buttonStyles.button_link
                }
                to={constructPath(item.path || item.to)} // Use constructPath to handle paths correctly
                onClick={onCloseMenu}>
                {item.label}
              </Button>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Menu;
