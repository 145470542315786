export const MAINTENANCE_MODE = false;
export const HOLDING_MODE = false;

export const SITE_NAME = "Channel-Sec";
export const EVENT_VENUE = "Forest of Arden, Birmingham";
export const EVENT_ADDRESS = "Forest of Arden, Birmingham, GB CV7 7HR";
export const EVENT_DATE_STRING = "2025-05-08";
export const EVENT_END_DATE_STRING = "2025-05-09";
export const EVENT_TITLE = `Drive your cyber growth at ${SITE_NAME} 2025`
export const EVENT_SLOGAN = "The event for building powerful propositions and partnerships"
export const EVENT_REGISTRATION = "https://www.delegateselect.com/event/channel-sec/";
export const EVENT_LOGIN = "https://www.delegateselect.com/event/channel-sec/login.html";

const today = new Date();
export const CURRENT_YEAR = today.getFullYear();

/* Provide various event date formats */
const eventDate = new Date(EVENT_DATE_STRING);
const eventEndDate = new Date(EVENT_END_DATE_STRING);

export const EVENT_DATE = eventDate.toLocaleDateString('en-GB', {
  weekday: 'long',  // Thursday
  month: 'long',    // May
  day: '2-digit',   // 18
  year: 'numeric'   // 2023
});

export const EVENT_YEAR = eventDate.toLocaleString('en-GB', {
  year: 'numeric'
})

// Create a Date object for the previous year
const previousYearDate = new Date(eventDate);

previousYearDate.setFullYear(eventDate.getFullYear() - 1);

export const PREVIOUS_EVENT_YEAR = previousYearDate.toLocaleString('en-GB', {
  year: 'numeric'  // 2023
});


function getOrdinalSuffix(day) {
  if (day % 10 === 1 && day !== 11) {
    return 'st';
  } else if (day % 10 === 2 && day !== 12) {
    return 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    return 'rd';
  }
  return 'th';
}

const weekdayName = eventDate.toLocaleDateString('en-GB', {
  weekday: 'long',
});
const monthName = eventDate.toLocaleDateString('en-GB', {
  month: 'long',
});
const yearValue = eventDate.toLocaleDateString('en-GB', {
  year: 'numeric',
});
const dayWithoutSuffix = eventDate.toLocaleDateString('en-GB', {
  day: 'numeric',
});

const dayWithSuffix = `${dayWithoutSuffix}${getOrdinalSuffix(Number(dayWithoutSuffix))}`;

export const EVENT_DATE_SUFFIXED = `${weekdayName} ${dayWithSuffix} ${monthName} ${yearValue}`;


// Extract values for the start date
const startDay = eventDate.toLocaleDateString('en-GB', { day: 'numeric' });
const startDayWithSuffix = `${startDay}${getOrdinalSuffix(Number(startDay))}`;
const startMonthName = eventDate.toLocaleDateString('en-GB', { month: 'long' });
const startYear = eventDate.toLocaleDateString('en-GB', { year: 'numeric' });

// Extract values for the end date
const endDay = eventEndDate.toLocaleDateString('en-GB', { day: 'numeric' });
const endDayWithSuffix = `${endDay}${getOrdinalSuffix(Number(endDay))}`;
const endMonthName = eventEndDate.toLocaleDateString('en-GB', { month: 'long' });
const endYear = eventEndDate.toLocaleDateString('en-GB', { year: 'numeric' });

// Combine dates into a single range string
export const EVENT_DATE_RANGE = 
  startMonthName === endMonthName && startYear === endYear
    ? `${startDayWithSuffix} - ${endDayWithSuffix} ${startMonthName} ${startYear}` // Same month and year
    : `${startDayWithSuffix} ${startMonthName} ${startYear} - ${endDayWithSuffix} ${endMonthName} ${endYear}`; // Different month or year